<template>
  <div class="card-body" style="min-width: 40vw">
    <h4 class="card-title">Datos de licencia</h4>
    <div>
      <!-- <h4 class="mt-1 mb-1">Empleado</h4> -->
      <div class="cols2">
        <pd-input
          class=""
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="licencia.persona.nombre"
          label="Nombre"
          id="nombre"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class=""
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="licencia.persona.cuil"
          label="CUIL"
          id="cuil"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <!-- <h4 class="mt-1 mb-1">Licencia</h4> -->
      <div class="cols2">
        <pd-input
          class=""
          readonly
          classInput="form-control readonly text-bold"
          v-model="licencia.tipo_licencia_descripcion"
          label="Tipo de licencia"
          id="tipo_licencia"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class=""
          readonly
          classInput="form-control readonly text-bold"
          v-model="licencia.tipo_licencia_convenio"
          label="Convenio"
          id="convenio"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <div class="cols2">
        <pd-input
          class=""
          readonly
          classInput="form-control readonly"
          v-model="licencia.periodo"
          label="Período"
          id="periodo"
          placeholder="periodo"
        ></pd-input>
        <pd-input
          class=""
          type="date"
          readonly
          classInput="form-control readonly"
          v-model="licencia.fecha_solicitud"
          label="Fecha de solicitud"
          id="fecha_solicitud"
          placeholder="fecha_solicitud"
        ></pd-input>
      </div>
      <div class="my-1 cols2">
        <pd-input
          class=""
          type="date"
          readonly
          classInput="form-control readonly"
          v-model="licencia.desde"
          label="Desde"
          id="desde"
          placeholder="desde"
        ></pd-input>
        <pd-input
          class=""
          type="date"
          readonly
          classInput="form-control readonly"
          v-model="licencia.hasta"
          label="Hasta"
          id="hasta"
          placeholder="hasta"
        ></pd-input>
        <pd-input
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="licencia.cantidad_dias"
          label="Cantidad de días"
          id="cantidad_dias"
          placeholder="cantidad_dias"
        ></pd-input>
        <pd-input
          class=""
          type="date"
          readonly
          classInput="form-control readonly"
          v-model="licencia.fecha_reincorporacion"
          label="Fecha de reincorporación"
          id="fecha_reincorporacion"
          placeholder="fecha_reincorporacion"
        ></pd-input>
      </div>
      <div
        class="my-1 cols2"
        v-if="licencia.fecha_probable_parto && licencia.dias_previos"
      >
        <pd-input
          class=""
          type="date"
          readonly
          classInput="form-control readonly"
          v-model="licencia.fecha_probable_parto"
          label="Fecha probable de parto"
          id="fecha_parto"
          placeholder="Fecha probable de parto"
        ></pd-input>
        <pd-input
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="licencia.dias_previos"
          label="Días previos a fecha de parto"
          id="dias_previos"
          placeholder="Días previos a fecha de parto"
        ></pd-input>
      </div>
      <div class="cols2 my-1">
        <pd-input
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="licencia.gozados"
          :label="'Días gozados para ' + licencia.periodo"
          id="corresponden"
          placeholder="corresponden"
        ></pd-input>
        <pd-input
          v-if="dias_trabajados"
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="dias_trabajados"
          label="Días trabajados"
          id="dias_trabajados"
          placeholder=""
        ></pd-input>
        <pd-input
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="corresponden"
          :label="'Días disponibles para ' + licencia.periodo"
          id="corresponden"
          placeholder="corresponden"
        ></pd-input>
        <pd-input
          class=""
          type="text"
          readonly
          classInput="form-control readonly"
          v-model="pendientes"
          :label="'Días pendientes de goce en ' + licencia.periodo"
          id="corresponden"
          placeholder="corresponden"
        ></pd-input>
      </div>
      <div class="d-flex space-between mt-1">
        <button type="button" class="btn btn-error" @click="eliminarLicencia">
          Eliminar
        </button>
        <button type="button" class="btn btn-warning" @click="editarLicencia">
          Editar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { LicenciasServices } from "@/modules/licencias/services/LicenciasServices";
import Swal from "sweetalert2";
export default {
  name: "ShowLicencia",
  props: ["licencia"],
  computed: {
    corresponden() {
      if (this.licencia.corresponden != null) {
        return this.licencia.corresponden;
      } else {
        return "Sin tope";
      }
    },
    pendientes() {
      if (this.licencia.pendientes != null) {
        return this.licencia.pendientes;
      } else {
        return "Sin tope";
      }
    },
    dias_trabajados() {
      if (this.licencia.dias_trabajados.length > 0) {
        let fechas = "";
        for (let dia_trabajado of this.licencia.dias_trabajados) {
          fechas += fechas != "" ? " - " : "";
          fechas += dia_trabajado.fecha.split("-").reverse().join("/");
        }
        return fechas;
      } else {
        return false;
      }
    },
  },
  methods: {
    eliminarLicencia() {
      LicenciasServices.api
        .delete(this.licencia)
        .then((r) => {
          if (r.success == true) {
            this.$emit("deleted");
            Swal.fire("Éxito", `<p>Se eliminó con éxito.</p>`, "success");
          } else {
            Swal.fire("Error", `<p>${r.message}</p>`, "error");
          }
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${r.message}</p>`, "error");
        });
    },
    editarLicencia(){
      this.$emit('edit', this.licencia)
    }
  },
};
</script>
