import axios from 'axios';

export default {
  api: {
    async getGuardias({desde, hasta, area}) {
      return axios
        .get('guardia', {params: {desde, hasta, area}})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async getGuardiasByDay(fecha) {
      return axios
        .get('guardias', {params: {fecha: fecha}})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async addGuardia(guardia) {
      return axios
        .post('guardia', guardia)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async editGuardia(guardia) {
      return axios
        .put('guardia/' + guardia.id, guardia)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async deleteGuardia(guardiaId) {
      return axios
        .delete('guardia/' + guardiaId)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async validarExistente(desde, hasta, persona_id) {
      return axios
        .get('guardia/validar-existente', {params: {desde, hasta, persona_id}})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
