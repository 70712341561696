<template>
  <div class="container">
    <div class="dp-mask" v-show="panelShow" @click="panelShow = false"></div>
    <div class="selected-date" @click="panelShow = !panelShow">
      <div style="display: flex; flex-wrap: wrap" v-if="multi">
        <div class="sel-values" v-for="(item, index) in selected" :key="index">
          {{ format(item) }}
        </div>
      </div>
      <div v-else>
        {{ selected ? format(selected) : "" }}
      </div>
    </div>
    <transition name="smooth">
      <div
        class="pick-panel"
        :style="{ position: alwaysDisplay ? 'inherit' : 'absolute' }"
        v-show="alwaysDisplay || panelShow"
      >
        <div class="dp-header">
          <button type="button" class="btn btn-flat-dark select-header" @click="seleYear--">
            <<
          </button>
          <button type="button"
            class="btn btn-flat-dark select-header"
            @click="changeMonth(-1)"
          >
            <
          </button>

          <span class="selected-month"
            >{{ monthNames[seleMonth] }} {{ seleYear }}
          </span>

          <button type="button"
            class="btn btn-flat-dark select-header"
            @click="changeMonth(1)"
          >
            >
          </button>
          <button type="button" class="btn btn-flat-dark select-header" @click="seleYear++">
            >>
          </button>
        </div>
        <div class="dp-body">
          <div class="cal-container">
            <div
              class="cal-item cal-head"
              v-for="day in display.days"
              :key="day"
            >
              {{ day }}
            </div>
            <div
              class="cal-item"
              @click="toggleSelect(item)"
              v-for="(item, index) in renderCalendar"
              :key="index"
              :class="[
                item.iscur ? 'cal-enable' : 'cal-disable',
                selectIndex(item) >= 0 ? 'cal-select' : '',
              ]"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dp-footer" v-show="multi">
          <button class="btn btn-flat-error" @click="cancelSelect">
            {{ alwaysDisplay ? display.clear : display.cancel }}
          </button>
          <button
            v-if="!alwaysDisplay"
            class="btn btn-flat-dark"
            @click="submitSelect(selected)"
          >
            {{ display.ok }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "DateSelect",
  data() {
    return {
      panelShow: false,
      seleDate: new Date(),
      seleYear: new Date().getFullYear(),
      seleMonth: new Date().getMonth(),
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  props: {
    multi: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array | String | Date,
      default: [],
    },
    lang: {
      type: String,
      default: "zh",
    },
    format: {
      type: Function,
      default: (date) => date.toLocaleDateString(),
    },
    alwaysDisplay: {
      type: Boolean,
      default: false,
    },
    disp: {
      type: Array,
      default: function () {
        if (this.lang === "en") {
          return [
            "Sun",
            "M",
            "T",
            "W",
            "Th",
            "F",
            "Sat",
            "Year",
            "Month",
            "Cancel",
            "OK",
            "Clear",
          ];
        } else {
          return [
            "Do.",
            "Lu.",
            "Ma.",
            "Mi.",
            "Ju.",
            "Vi.",
            "Sa.",
            "Año",
            "Mes",
            "Cancelar",
            "OK",
            "Reset",
          ];
        }
      },
    },
  },
  computed: {
    renderCalendar: function () {
      let firDay = 0 - new Date(this.seleYear, this.seleMonth, 1).getDay() + 1;
      let res = [];
      for (let i = firDay, index = 0; index < 42; i++, index++) {
        let day = new Date(this.seleYear, this.seleMonth, i, 8);
        let calObj = {
          label: day.getDate(),
          date: day,
          iscur: day.getMonth() === this.seleMonth,
        };
        res.push(calObj);
      }
      return res;
    },
    selected: function () {
      return this.value;
    },
    display: function () {
      let d = this.disp;
      return {
        days: d.slice(0, 7),
        year: d[7],
        month: d[8],
        cancel: d[9],
        ok: d[10],
        clear: d[11],
      };
    },
  },
  methods: {
    selectIndex: function (item) {
      if (!this.multi || !this.selected) {
        return -1;
      }
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].getTime() == item.date.getTime()) {
          return i;
        }
      }
      return -1;
    },
    cancelSelect: function () {
      if (this.multi) {
        this.selected.splice(0, this.selected.length);
      } else {
        this.selected = null;
      }
      this.panelShow = false;
    },
    toggleSelect: function (item) {
      if (!this.multi) {
        this.submitSelect(item.date);
      } else {
        let index = this.selectIndex(item);
        if (index < 0) {
          this.selected.push(item.date);
        } else {
          this.selected.splice(index, 1);
        }
      }
    },
    submitSelect: function (value) {
      this.$emit("input", value);
      this.panelShow = false;
    },
    changeMonth(num) {
      if (this.seleMonth + num > 11) {
        this.seleMonth = 0;
        this.seleYear++;
      } else if (this.seleMonth + num < 0) {
        this.seleMonth = 11;
        this.seleYear--;
      } else {
        this.seleMonth += num;
      }
    },
  },
};
</script>
<style scoped>
.dp-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.selected-date {
  cursor: pointer;
  min-height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.438rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 0.9rem;
  line-height: 0.9rem;
  min-height:34px
}
.sel-values {
  background-color: var(--neutral-20);
  border-color: var(--neutral-30);
  color: var(--body-font);
  box-sizing: border-box;
  margin: 0 5px;
  padding: .148rem 5px;
  font-size:.7rem;
  border-radius: 4px;
  line-height:.9rem;
}

.pick-panel {
  width: 280px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--body-font);
  border: 1px solid var(--neutral-20);
  box-shadow: var(--shadow-2);
  background: #fff;
  border-radius: 2px;
  line-height: 20px;
  margin: 5px 0;
  z-index: 10;
}

.dp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
}
.select-header {
  padding: 3px 5px;
  min-width: auto;
}
.selected-month {
  width: 125px;
  text-align: center;
  font-size: 0.85rem;
}
.cal-container {
  width: calc(7 * 34px);
  display: flex;
  flex-wrap: wrap;
}
.cal-item.cal-head {
  font-size: 0.8rem;
}
.cal-item {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  font-size: 0.9rem;
}
.cal-enable {
  cursor: pointer;
}
.cal-enable:hover {
  background-color: var(--neutral-30);
}
.cal-disable {
  color: var(--neutral-40);
  cursor: default;
}
.cal-select {
  background-color: var(--purple-20);
}

.dp-footer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin: 5px 0;
  border-top: 1px solid var(--neutral-20);
}
.dp-footer > .btn {
  width: auto;
  padding: 5px 8px;
  min-width: 85px;
}
.smooth-enter-active {
  transition: all 0.5s ease-in-out;
}
.smooth-leave-active {
  transition: all 0.5s ease-in-out;
}
.smooth-enter,
.smooth-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>