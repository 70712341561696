<template>
  <div class="modal-form">
    <h3 class="card-title">Cargar Licencia</h3>
    <form @submit.prevent="save">
      <suggest
        id="persona"
        label="Persona"
        placeholder="Buscar persona.."
        class="my-1"
        :data="personas"
        :items="personaList"
        @selected="personaSelected"
        :required="enviado && !persona"
      ></suggest>
      <suggest
        id="tipo_licencia"
        label="Licencia"
        placeholder="Buscar licencia.."
        class="my-1"
        :data="tiposLicencia"
        :items="tipoLicenciaList"
        @selected="tipoLicenciaSelected"
        :required="enviado && !tipoLicencia"
      ></suggest>
      <pd-input
        v-model="periodo"
        id="periodo"
        label="Período"
        class="my-1 mb-3"
        :select="true"
        :required="$v.periodo.$dirty && !$v.periodo.required"
      >
        <template slot="options">
          <option :value="null">Seleccione período</option>
          <option v-for="year in years" :value="year" :key="year">
            {{ year }}
          </option>
        </template>
      </pd-input>
      <!-- <pd-input
        v-model="selectLicencia"
        id="selectlicencia"
        label="Tipos de licencia"
        class="my-1"
        :select="true"
      >
        <template slot="options">
          <option :value="null">Seleccione tipo de licencia</option>
          <option v-for="tipo in tiposLicencia" :value="tipo.id" :key="tipo.id">{{tipo.descripcion}} - {{tipo.convenio}}</option>
        </template>
      </pd-input> -->
      <div class="form-group my-1" v-if="tipoLicencia && tipoLicencia.id == 21">
        <label class="form-label">Días trabajados (Solo para francos)</label>
        <date-select
          v-model="fecha_generadora"
          :multi="multi"
          :always-display="false"
          :format="formatDate"
        ></date-select>
      </div>

      <pd-input
        v-model="fecha_solicitud"
        id="fecha_solicitud"
        label="Fecha solicitud"
        placeholder="Fecha de solicitud"
        class="my-1 mb-3"
        type="date"
        :required="$v.fecha_solicitud.$dirty && !$v.fecha_solicitud.required"
      ></pd-input>

      <template v-if="tipoLicencia && tipoLicencia.id == 18">
        <pd-input
          v-model="dias_previos"
          id="dias_previos"
          label="Dias previos a fecha de parto"
          class="my-1"
          :select="true"
        >
          <template slot="options">
            <option :value="null">Seleccione una opción</option>
            <option value="30">30 días</option>
            <option value="45">45 días</option>
          </template>
        </pd-input>
        <pd-input
          v-model="fecha_probable_parto"
          id="fecha_probable_parto"
          label="Fecha probable de parto"
          placeholder="Fecha probable de parto"
          class="my-1 mb-3"
          type="date"
        ></pd-input>
      </template>

      <pd-input
        v-model="desde"
        id="desde"
        label="Fecha inicio licencia"
        placeholder="Fecha de inicio de licencia"
        class="my-1"
        type="date"
        :required="$v.desde.$dirty && !$v.desde.required"
      ></pd-input>
      <pd-input
        v-model="hasta"
        id="desde"
        label="Fecha finalización licencia"
        placeholder="Fecha de finalización de licencia"
        class="my-1"
        type="date"
        :required="$v.hasta.$dirty && !$v.hasta.required"
      ></pd-input>
      <pd-input
        v-model="cantidad_dias"
        id="cantidad_dias"
        label="Cantidad de días"
        placeholder="Cantidad de días"
        class="my-1 mb-3"
        :readonly="true"
        type="text"
        inputmode="numeric"
        :required="enviado && !$v.cantidad_dias.required"
      ></pd-input>
      <pd-input
        v-model="fecha_reincorporacion"
        id="fecha_reincorporacion"
        label="Fecha de reincorporación"
        placeholder="Fecha de reincorporación"
        class="my-1"
        type="date"
        :required="
          $v.fecha_reincorporacion.$dirty && !$v.fecha_reincorporacion.required
        "
      ></pd-input>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import DateSelect from "@/components/elements/DateSelect";
import Suggest from "@/components/elements/Suggest";
import {PersonasService} from "@/modules/personas/services/PersonasServices";
import {TiposLicenciasServices} from "@/modules/tipos_licencias/services/TiposLicenciasServices";
import {LicenciasServices} from "../services/LicenciasServices";
import GuardiaServices from '../../guardias/services/GuardiaServices';
import Modal from "../../../components/elements/Modal.vue";

export default {
  name: "NuevaLicencia",
  components: {
    Modal,
    DateSelect,
    Suggest,
  },
  data() {
    return {
      periodo: null,
      multi: true,
      fecha_generadora: [],
      fecha_solicitud: null,
      fecha_probable_parto: null,
      dias_previos: null,
      cantidad_dias: null,
      desde: null,
      hasta: null,
      fecha_reincorporacion: null,
      dias_correspondientes: null,
      personas: null,
      persona: null,
      personaList: PersonasService.dataList(),

      selectLicencia: null,
      tiposLicencia: null,
      tipoLicencia: null,
      tipoLicenciaList: TiposLicenciasServices.dataList,

      enviado: false,
    };
  },
  watch: {
    desde() {
      this.checkDesdeHasta();
    },
    hasta() {
      this.checkDesdeHasta();
      if (this.hasta) {
        let fecha_hasta = new Date(this.hasta);
        fecha_hasta.setDate(fecha_hasta.getDate() + 1);
        this.fecha_reincorporacion = fecha_hasta.toISOString().slice(0, 10);
      }
    },
    fecha_probable_parto() {
      this.setMaternidad();
    },
    dias_previos() {
      this.setMaternidad();
    },
  },
  validations: {
    ...LicenciasServices.validations,
  },
  computed: {
    years: function () {
      let currentYear = new Date().getFullYear();
      let array = [];
      for (let index = 2019; index <= currentYear; index++) {
        array.push(index);
      }
      return array
    },
  },
  mounted() {
    let today = new Date();
    this.periodo = today.getFullYear();
    PersonasService.api.getAllForSelect().then((personas) => (this.personas = personas));
    TiposLicenciasServices.api
      .getAll()
      .then((tiposLicencia) => (this.tiposLicencia = tiposLicencia));
  },

  methods: {
    formatDate(date) {
      return date.toLocaleDateString();
    },
    personaSelected(persona) {
      this.persona = persona;
    },
    tipoLicenciaSelected(tipoLicencia) {
      this.tipoLicencia = tipoLicencia;
    },
    async save() {
      this.enviado = true;
      this.$v.$touch();
      if (
        this.$v.$invalid ||
        !this.persona ||
        !this.tipoLicencia ||
        (this.tipoLicencia.id == 21 && this.fecha_generadora.length == 0)
      ) {
        return false;
      }
      if(await this.validarAntiguedadParaVacaciones() !== true){
        return
      }
      let dias_trabajados = Array();
      for (let dia of this.fecha_generadora) {
        dias_trabajados.push(dia.toISOString().slice(0, 10));
      }
      let form = {
        persona_id: this.persona.id,
        tipo_licencia_id: this.tipoLicencia.id,
        periodo: this.periodo,
        fecha_solicitud: this.fecha_solicitud,
        fecha_probable_parto: this.fecha_probable_parto,
        dias_previos: this.dias_previos,
        desde: this.desde,
        hasta: this.hasta,
        fecha_generadora: dias_trabajados,
        cantidad_dias: this.cantidad_dias,
        fecha_reincorporacion: this.fecha_reincorporacion,
        dias_correspondientes: this.dias_correspondientes
      };
      LicenciasServices.api
        .save(form)
        .then(async (r) => {
          if (r.success == true) {
            await Swal.fire("Éxito", `<p>Se guardó con éxito.</p>`, "success");
            this.$emit("saved", r.reemplazoGuardia);
          } else {
            Swal.fire("Error", `<p>${r.message}</p>`, "error");
          }
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
    async validarAntiguedadParaVacaciones(){
      if (this.tipoLicencia.id == 24 || this.tipoLicencia.id == 23) {
        const periodoDate = new Date(`${this.periodo}-12-31T00:00:00`);
        const ingresoDate = new Date(`${this.persona.fecha_ingreso}T00:00:00`);
        const antiguedad = this.monthDiff(ingresoDate, periodoDate);
        if (antiguedad < 6) {
          const result = await Swal.fire({
            title:
              "El empleado tiene menos de 6 meses de antiguedad para el período " +
              this.periodo,
            text: "Por favor, ingrese la cantidad de días de vacaciones que le corresponden",
            input: "number",
            inputAttributes: {
              autocapitalize: "off",
            },
            inputValidator: (value) => {
              if (!value) {
                return "Debe cargar la cantidad de días";
              }
            },
            showCancelButton: true,
            confirmButtonText: "Guardar",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
          });
          if (result.isDenied || result.isDismissed) {
            return false;
          }
          this.dias_correspondientes = result.value;
          if (this.dias_correspondientes < this.cantidad_dias) {
            Swal.fire(
              "Error",
              `<p>La cantidad de días no puede ser mayor a los días correspondientes</p>`,
              "error"
            );
            return false;
          }
        }
      }
      return true
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months;
    },
    validateDate(date) {
      let controlDate = new Date("01-01-2000"); //dd-mm-YYYY
      if (date) {
        let newDate = new Date(date) ?? null; //dd-mm-YYYY
        if (newDate > controlDate) {
          return true;
        }
      }
      return false;
    },
    checkDesdeHasta() {
      const DAY_TIME = 1000 * 3600 * 24;
      let desde = this.validateDate(this.desde) ? new Date(this.desde) : null;
      let hasta = this.validateDate(this.hasta) ? new Date(this.hasta) : null;
      if (desde && hasta) {
        if (desde <= hasta) {
          let diff = (hasta.getTime() - desde.getTime()) / DAY_TIME;
          this.cantidad_dias = diff + 1;
        } else {
          this.cantidad_dias = null;
          Swal.fire(
            "Error",
            "La fecha de inicio no puede ser mayor a la fecha de finalización",
            "error"
          );
        }
      }
    },
    setMaternidad() {
      if (this.dias_previos && this.fecha_probable_parto) {
        if (this.validateDate(this.fecha_probable_parto)) {
          const fecha_parto = new Date(this.fecha_probable_parto);
          const dias_previos = parseInt(this.dias_previos);
          const dias_siguientes = 90 - dias_previos;
          const calculateDesde = fecha_parto.getDate() - dias_previos;
          const calculateHasta = fecha_parto.getDate() + dias_siguientes - 1;
          let fecha_desde = new Date(fecha_parto.toISOString().slice(0, 10));
          fecha_desde.setDate(calculateDesde);
          this.desde = fecha_desde.toISOString().slice(0, 10);

          let fecha_hasta = new Date(fecha_parto.toISOString().slice(0, 10));
          fecha_hasta.setDate(calculateHasta);
          this.hasta = fecha_hasta.toISOString().slice(0, 10);
        }
      }
    },
  },
};
</script>
